import React from "react";
import { NameField, EmailField, Textarea, Wrapper, Spacer, FileField } from "../../components";
import "./ContactForm.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import LazyReCaptcha from "../../components/ReCaptcha/LazyReCaptcha";
import { isFieldNotEmpty, isEmailCorrect } from "../../utils/formValidator.utils";
import { formSubmitEvent } from "../../utils/gtmEvents.utils";

class ContactForm extends React.Component {
  name = React.createRef();
  email = React.createRef();
  message = React.createRef();
  file = React.createRef();
  form = React.createRef();

  state = {
    form: {
      name: {
        isValue: false,
        empty: false,
      },
      email: {
        isValue: false,
        isEmail: false,
        empty: false,
        notEmail: false,
      },
      message: {
        isValue: false,
        empty: false,
      },
      file: {
        isValue: false,
        empty: false,
      },
      reCaptcha: {
        empty: false,
      },
    },
    isReCaptchaEmpty: false,
    isReCaptchaValid: false,
    showReCaptcha: false,

    nameValue: "",
    emailValue: "",
    messageValue: "",
    fileValue: "",

    fileName: "",
  };

  checkFields = () => {
    let form = { ...this.state.form };
    form.name.isValue = isFieldNotEmpty(this.name.current.value);
    form.email.isValue = isFieldNotEmpty(this.email.current.value);
    form.email.isEmail = isEmailCorrect(this.email.current.value);
    form.message.isValue = isFieldNotEmpty(this.message.current.value);
    form.file.isValue = !this.props.fileAttach || this.file.current.files.length > 0;

    this.setState({ form: form });
    return (
      form.name.isValue && form.email.isValue && form.email.isEmail && form.message.isValue && form.file.isValue
    );
  };

  indicateFields = () => {
    let form = { ...this.state.form };
    form.name.empty = !isFieldNotEmpty(this.name.current.value);
    form.email.empty = !isFieldNotEmpty(this.email.current.value);
    form.email.notEmail = !isEmailCorrect(this.email.current.value);
    form.message.empty = !isFieldNotEmpty(this.message.current.value);
    form.file.empty = this.props.fileAttach && this.file.current.files.length === 0;

    let isReCaptchaEmpty = this.state.showReCaptcha && !this.state.isReCaptchaValid;
    this.setState({ form: form, isReCaptchaEmpty: isReCaptchaEmpty });
  };

  resetForm = () => {
    this.name.current.value = "";
    this.email.current.value = "";
    this.message.current.value = "";
    if (this.file.current) {
      this.file.current.value = "";
      this.setState({ fileName: "" });
    }
  };

  updateForm = () => {
    this.setState({
      nameValue: this.name.current.value,
      emailValue: this.email.current.value,
      messageValue: this.message.current.value,
      fileValue: this.file.current ? this.file.current.files[0] : "",
    });
  };

  onChangeHandler = () => {
    this.updateForm();
    this.setState({ isReCaptchaEmpty: false });
    if (this.checkFields()) this.setState({ showReCaptcha: true });
    else this.setState({ showReCaptcha: false });
  };

  onConfirmReCaptcha = () => this.setState({ isReCaptchaEmpty: false, isReCaptchaValid: true });

  onSubmitHandler = () => {
    if (this.checkFields() && this.state.isReCaptchaValid) this.submitForm();
    else this.indicateFields();
  };

  onFocusHandler = element => {
    let form = { ...this.state.form };
    if (element === "email") form[element].notEmail = false;
    form[element].empty = false;
    this.setState({ form: form });
  };

  submitForm = () => {
    fetch("/send-email", {
      method: "POST",
      body: new FormData(this.form.current),
    })
      .then(res => {
        if (res.status === 200) {
          toast("Thanks, your message has been successfully sent! We'll get back to you shortly.");
        } else {
          toast("Your message cannot be sent at the moment.");
        }
      })
      .catch(e => {
        console.error(e);
        toast("Your message cannot be sent at the moment.");
      });
    formSubmitEvent(); // gtm
    this.resetForm();
  };

  getFileName = () => {
    const form = { ...this.state.form };
    form.file.empty = false;
    this.setState({ form: form });

    if (this.file.current.files[0]) {
      this.setState({ fileName: this.file.current.files[0].name });
    } else {
      this.setState({ fileName: "" });
    }
  };

  render() {
    return (
      <Wrapper>
        <ToastContainer progressClassName={"fancy-progress-bar"} />
        <section className="cta-wrapper">
          {!this.props.fileAttach && (
            <React.Fragment>
              <h2 className="cta-title" id="contact-title">
                Let's talk about your project
              </h2>
              <p className="cta-description">Drop us a line! We would love to hear from you. </p>
            </React.Fragment>
          )}
          {this.props.fileAttach && (
            <React.Fragment>
              <h2 className="cta-title" id="contact-title">
                Send us <strong> your CV! </strong>
              </h2>
              <h5 className="cta-description mt-15">
                If you did not find a vacancy - send your CV to us. Our recruiters will contact you when a
                suitable position is available.
              </h5>
            </React.Fragment>
          )}
          <div className="cta-content">
            {!this.props.hideContacts && <div className="cta-info">
              <div>
                <div className="cta-photo">
                  <img
                      src={this.props.linkedinPhoto ?? "/img/contactForm/resized/richard_2.webp"}
                      alt="Richard"
                      loading="lazy"
                  />
                </div>
                <ul>
                  <li>
                    <a
                      className="lnkdn"
                      href={this.props.linkedinLink ?? "https://www.linkedin.com/in/richardzaslavsky/"}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        className="icon lnkdn"
                        src="https://elitex.systems/blog/wp-content/uploads/2022/06/linkedin.png"
                        alt="linkedin"
                        loading="lazy"
                        width="58"
                        height="58"
                      />
                      <span className="txt-wrp lnkdn">
                        <span className="cta-info-title lnkdn">{this.props.linkedinName ?? "Richard Zaslavsky"}</span>
                        <span className="cta-info-desc lnkdn">{this.props.linkedinPosition ?? "Client Partner"}</span>
                      </span>
                    </a>
                  </li>
                  {!this.props.hideCalendly && <li>
                    <a
                      className="clndl"
                      href="https://calendly.com/elitexsystems"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        className="icon clndl"
                        src="https://elitex.systems/blog/wp-content/uploads/2022/06/calendly.png"
                        alt="calendly"
                        loading="lazy"
                        width="58"
                        height="58"
                      />
                      <span className="txt-wrp">
                        <span className="cta-info-title clndl"> Book a Call </span>
                      </span>
                    </a>
                  </li>}
                </ul>
              </div>
            </div>}
            <div className={this.props.hideContacts ? "cta-form cta-form-center" : "cta-form"}>
              <form onChange={this.onChangeHandler} ref={this.form}>
                <NameField
                  reference={this.name}
                  defaultValue={this.state.nameValue}
                  onFocus={this.onFocusHandler.bind(this, "name")}
                  errorBorder={this.state.form.name.empty}
                  errorMessage={this.state.form.name.empty}
                />
                <EmailField
                  reference={this.email}
                  defaultValue={this.state.emailValue}
                  onFocus={this.onFocusHandler.bind(this, "email")}
                  errorBorder={this.state.form.email.empty}
                  errorMessage={this.state.form.email.empty}
                  errorMessage2={this.state.form.email.notEmail && !this.state.form.email.empty}
                />
                <div className="email-form-half">
                  <Textarea
                    reference={this.message}
                    defaultValue={this.state.messageValue}
                    onFocus={this.onFocusHandler.bind(this, "message")}
                    errorBorder={this.state.form.message.empty}
                    errorMessage={this.state.form.message.empty}
                  />
                </div>
                {/*show file input on career page*/}
                {this.props.fileAttach && (
                  <FileField
                    onChange={this.getFileName}
                    reference={this.file}
                    fileName={this.state.fileName}
                    errorMessage={this.state.form.file.empty}
                  />
                )}
                {/*loading reCaptcha lazily*/}
                {this.state.showReCaptcha && (
                  <LazyReCaptcha onChange={this.onConfirmReCaptcha} errorMessage={this.state.isReCaptchaEmpty} />
                )}
                <Spacer type="mini" />
                <input
                  type="button"
                  className="btn btn-default submit"
                  value="Send Message"
                  onClick={this.onSubmitHandler}
                />
              </form>
            </div>
          </div>
        </section>
      </Wrapper>
    );
  }
}

export { ContactForm };
