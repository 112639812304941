// MAIN CAPTION

export const feMainCaptionTitle = "Front-End Development Services";
export const feMainCaptionText = "A strong online presence is essential in the digital age. At ELITEX, our front-end development team creates advanced web apps that look great, perform flawlessly, and align with your business goals.";

// FRONT-END INFO
export const infoText = 'A solid online presence is essential for anyone looking to succeed in the modern digital age. Your website or application serves as the face of your company, and it’s often the first point of contact and a way to gain customer loyalty. At ELITEX, we understand the significance of having a web app that looks great and performs flawlessly. Our front-end development team has extensive experience creating tailored advanced solutions for your business goals. Modern technology and adhering to industry best practices ensure the product’s quality, exceptional visual appeal, rich user interfaces, and optimization for high performance.';


// WHAT DO WE OFFER

export const feWhatOfferTitle = {
    main: "Front-End Development Services:",
    strong: "What Do We Offer"
}
export const feWhatDoWeOfferContent = [
    { 
        id: 1,
        name: "Custom front-end development",
        text: "Our software developers create bespoke responsive websites and applications tailored to your business objectives. We take pride in providing high-quality web, desktop, and mobile apps optimized for speed and performance. Our devs' tremendous technical skills ensure the product's efficiency, security, reliability, and user-friendliness.",
        img: "/img/frontEnd/offer_1.webp"
    },
    {
        id: 2,
        name: "Web design services",
        text: "Comprehensive design services encompass every aspect of building and maintaining an application development. We provide you with everything you need to achieve a successful digital presence: from creating an enhanced user experience and a seamless user interface (UX/UI) to responsive design solutions built for the unique goals of your project.",
        img: "/img/frontEnd/offer_2.webp"
    },
    {
        id: 3,
        name: "Front-end consulting: modernization and optimization",
        text: "ELITEX's experts offer guidance and insights for your development project, focusing on front-end modernization and optimization. Our frontend developers improve code quality, migrate to new tech stacks, and enhance loading time, website speed, accessibility, and user experience.",
        img: "/img/frontEnd/offer_3.webp"
    },
    {
        id: 4,
        name: "Maintenance and support",
        text: "Ongoing services ensure the smooth operation of the website/application or its components. Maintenance and support refer to regular updates and bug fixes to prevent costly downtime or errors. By building reliable long-term partner relationships for ongoing support, businesses can ensure the proper functioning of the product, providing a seamless user experience.",
        img: "/img/frontEnd/offer_4.webp"
    },
];

export const whatDoWeOfferTextOne= "Being an experienced front end development software company, we deliver a wide range of services to help you create engaging and intuitive user experiences. Whether you need a complete overhaul of your existing project or a custom application developed from scratch, we have what to offer you.";
export const whatDoWeOfferTextTwo = "In addition to a range of custom front-end development services, consulting, web/app design, and ongoing support, we offer backend development, third-party API integration, cross-browser compatibility testing, web accessibility compliance auditing, e-commerce solutions, effective data management, database design, and more.";
export const whatDoWeOfferTextThree = "If you need to expand your existing workflows with skilled professionals who work exclusively on your project, our dedicated development team can help. A flexible hiring model ensures transparent communication and complete control over development processes. Whatever your project requirements may be, ELITEX is here to help you succeed.";


// FE DEVELOPMENT PROCESS

export const devProcessTextOne = "The front-end development process involves a series of steps that enable front-end developers to build user-facing components, an essential aspect of web development. A successful web project requires a comprehensive approach that considers different spheres. That’s why front-end developers collaborate with designers, back-end developers, and project managers. This collaboration ensures the end product meets the client’s goals and requirements.";
export const devProcessTextTwo = "However, the application development lifecycle is broad and also involves specific web technologies such as HTML, CSS, and JavaScript. These technologies primarily focus on creating the user interface design of the website or application. Front-end developers work to create an accessible and responsive design that works across multiple devices and platforms.";

export const devProcessTextThree = "Overall, it’s vital to follow a structured process to ensure the efficiency and high quality of the product. Let’s see how it works in practice. Usually, the full range of frontend development consists of the following stages:";

export const devProcessContent = [
    {
        id: 1,
        header:"Planning and research",
        text: "Defining the strategic goals of the project and the target audience: research user preferences, industry trends, and market analysis.",
        arrow: true,
    },
    {
        id: 2,
        header:"Prototyping and wireframing",
        text: "Creating a visual representation of the layout and user interface. It helps in better understanding any potential issues.",
        arrow: true,
    },
    {
        id: 3,
        header:"Design",
        text: "Designing involves creating graphic parts (color schemes, fonts, logos, and other visual elements) to improve user experience."
    },
    {
        id: 4,
        header:"Development",
        text: "Coding the website or app using different programming languages and front-end frameworks.",
        arrow: true,
    },
    {
        id: 5,
        header:"Testing and deployment",
        text: "Thorough testing for identifying any bugs or issues. We do testing across various platforms and devices to ensure compatibility. We deploy the product to the web server when it is ready for lunch and configure the server settings.",
        arrow: true,
    },
    {
        id: 6,
        header:"Maintenance and updates",
        text: "After the deployment, it’s crucial to make ongoing maintenance and updating for the product to keep it secure, fix any issues, and keep up with the latest trends and technology."
    },
]

// WHY CHOOSE ELITEX

export const whyChooseTitle = "Why Choose ELITEX as a Front End Development Company?";

export const whyChooseTextOne = "As an experienced Eastern European front end web development company, ELITEX has vast expertise in creating scalable, high-performance solutions. We work closely to understand our client's vision and appreciate feedback, ensuring it’s incorporated at every stage, delivering a product that meets needs and exceeds expectations. The client’s satisfaction is our top priority, and we strive to ensure that every project we undertake succeeds.";
export const whyChooseTextTwo = "We understand the importance of following industry-standard coding practices, continuous skill development, and using the latest technologies to ensure the application is efficient, responsive, and accessible. That’s why our team brings together exceptionally qualified, hand-picked engineers who share the ELITEX values to stay up-to-date. The logic is quite straightforward: we need the best people to be the best front end development company, and that’s the main principle of our work.";

export const whyChooseTextThree = "Our approach combines several principles: zero bureaucracy, ongoing self-improving, transparency in communication, and flexibility. Together it allows us to reduce additional development costs significantly: we offer modest hourly rates, much lower than in Western Europe or Northern America. At the same time, all our team members are fluent in English.";

export const whyChooseTextFour = " We value a reliable long-term partnership founded on confidence and mutual respect. Our track record shows that, on average, collaboration with our clients lasts for more than two years.";

export const whyChooseTextFive = "Working with ELITEX always means stability and fostering trustworthy and respectful cooperation. Book a call today and discover why ELITEX is rightfully considered the top front end development company.";

export const takeFirstStepText = "Take the First Step: Schedule a Project Consultation Today";

// CASE STUDIES

export const caseStudiesTitle = {
    main: "Discover Real-Life Examples of Web Development in Action Through",
    strong: "Our Case Studies"
};

// TECHNOLOGIES WE USE

export const technologiesUseTitle = "Technologies We Use in Our Development Services";

export const technologiesUseText = "We deliver top-quality front end web development services using cutting-edge technologies, advanced tools, and front-end frameworks. We are working on our coding practices and embracing emerging technologies to provide robust solutions and ensure our position at the forefront of the software development industry.";

// FREQUENTLY ASKED QUESTIONS
export const feFaqContent = [
    {
        id: 1,
        name: "What is front-end?",
        text: "Defining the strategic goals of the project and the target audience: research user preferences, industry trends, and market analysis.",
        state: "one"
    },
    {
        id: 2,
        name: "What is the best front-end programming language?",
        text: "There is no single “best” answer since the optimal choice of the development language depends on the project’s unique requirements and the particular front-end development agency’s approach. Usually, HTML, CSS, and JavaScript (including its frameworks and libraries such as Vue, Angular, React, and TypeScript) are considered the best front-end development languages."
    },
    {
        id: 3,
        name: "What is the difference between front-end and back-end development?",
        text: "Front-end development services handle the user interface and user experience of an app/website using HTML, CSS, and JS. In contrast, back-end development usually refers to designing and implementing server-side logic and database operations using languages like Node.js, PHP, Python, or Ruby."
    },
    {
        id: 4,
        name: "What is the typical amount of time required to develop a robust front-end website or application?",
        text: "The time required to develop a robust website or app depends on several factors, primarily the scope of work and the front-end web development company’s experience. The usual front-end development services cycle can take several weeks to months to complete a development project, while ongoing maintenance and support of successful projects should be provided for years."
    },
    {
        id: 5,
        name: "Why are front-end development services important?",
        text: "Today, the digital presence may slightly contribute to the successful branding of the business. Usually, a front end development company may offer a building of user-friendly websites and applications, connecting the back end with design solutions that will improve the overall user experience."
    },
];

// OTHER SERVICES SLIDER

export const servicesSliderTitle = {
    main: "You May Find Interesting",
    strong: "Other Services"
};


// ARTICLES

export const articlesTitle = "Related Content"