import React, {Fragment, useEffect} from 'react';
import './FrontEndDevelopment.css';
import HelmetSetter from "../../components/Helmet/Helmet";
import {
    CaseStudiesGrid,
    Feedback,
    Hero,
    PageBody,
    QaRelatedArticles,
    ScrollDownBtn,
    Spacer,
    WdsSlider,
    Wrapper
} from "../../components";
import {portfolioItem} from "../../utils";
import FrontEndInfo from "../../components/FrontEndInfo/FrontEndInfo";
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import FeDevProcess from "../../components/FeDevProcess/FeDevProcess";
import FeWhyChooseElitex from "../../components/FeWhyChooseElitex/FeWhyChooseElitex";
import {SliderMaker} from "../SliderMaker/SliderMaker";
import TechnologiesUse from "../../components/TechnologiesUse/TechnologiesUse";
import FAQ from "../../components/FAQ/FAQ";
import {ContactForm} from "../ContactForm/ContactForm";
import {
    articlesTitle,
    caseStudiesTitle,
    feFaqContent,
    feWhatOfferTitle,
    servicesSliderTitle,
    whatDoWeOfferTextOne,
    whatDoWeOfferTextThree,
    whatDoWeOfferTextTwo,
    homeClientsSliderHeader,
    feMainCaptionTitle,
    feMainCaptionText,
    homeFaqTitle,
    feWhatDoWeOfferContent
} from "../../mocks";
import {testimonialItems} from "../../mocks";
import {breadcrumbStructuredData, frontEndFaqStructuredData} from "../../utils/structuredData";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import BlueLine from "../../components/BlueLine/BlueLine";
import TechWhatOfferPhoto from "../../components/TechWhatOfferPhoto/TechWhatOfferPhoto";
import {MainCaption} from "../MainCaption/MainCaption";

export const FrontEndDevelopment = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    return (
        <Fragment>
            <HelmetSetter
                title="Front-End Development Services | ELITEX"
                description="Unlock the potential of your digital landscape with ELITEX, the trusted front end development company. Learn more about trends and the development process."
                ogImg="frontEnd/front-end-back.webp"
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Front-End Development',
                    item2: 'https://elitex.systems/front-end-development'
                })}
                faqStructuredData={frontEndFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img front-end">
                <Wrapper className="wrapper-medium">
                    <MainCaption title={feMainCaptionTitle} text={feMainCaptionText} contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
                <ScrollDownBtn/>
            </Hero>
            <PageBody>
                <FrontEndInfo/>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">{feWhatOfferTitle.main} <strong>{feWhatOfferTitle.strong}</strong></h2>
                    <BlueLine/>
                    <p className="fe-development-text">{whatDoWeOfferTextOne}</p>
                    <Spacer type="big"/>
                    <TechWhatOfferPhoto content={feWhatDoWeOfferContent}/>
                    <Spacer type="medium"/>
                    <p className="fe-development-text">{whatDoWeOfferTextTwo}</p>
                    <Spacer type="small"/>
                    <p className="fe-development-text">{whatDoWeOfferTextThree}</p>
                    <Spacer type="big"/>
                    <FeDevProcess/>
                    <Spacer type="big"/>
                    <FeWhyChooseElitex/>
                </Wrapper>
                <Spacer type="big"/>
                <div id="testimonial-slider">
                    <SliderMaker clientsSliderHeader={homeClientsSliderHeader} dark sliderItems={testimonialItems}
                                 SliderItem={Feedback} speed={15000} dots/>
                </div>
                <Spacer type="mini"/>
                <ProjectConsultation/>
                <Spacer type="big"/>
                <h2 className='h4 align-center'>{caseStudiesTitle.main} <strong>{caseStudiesTitle.strong}</strong></h2>
                <Spacer type="medium"/>
                <CaseStudiesGrid list={[
                    portfolioItem('kallik', false),
                    portfolioItem('hotelReservations', true),
                    portfolioItem('watstock', false),
                    portfolioItem('model-master', true),
                ]}/>
                <Spacer type="big"/>
                <Wrapper className="wrapper-medium">
                    <TechnologiesUse/>
                    <Spacer type="giant"/>
                    <FAQ title={homeFaqTitle} content={feFaqContent}/>
                    <Spacer type="big"/>
                    <h2 className='h4 align-center'>
                        <strong>{servicesSliderTitle.strong}</strong> {servicesSliderTitle.main}</h2>
                </Wrapper>
                <Spacer type="medium"/>
                <WdsSlider/>
                <Spacer type="big"/>
                <Wrapper>
                    <h2 className='h4 align-center'><strong>{articlesTitle}</strong></h2>
                    <Spacer type="medium"/>
                    <QaRelatedArticles/>
                </Wrapper>
                <Spacer type="big"/>
                <div className="fe-development-contact-form">
                    <ContactForm/>
                </div>
            </PageBody>
        </Fragment>
    );
};
