  // MainCaption
  const uiMainCaptionTitle ="UI/UX Development Services";
  const uiMainCaptionText = "User interface (UI) and user experience (UX) play a critical role in a business's success in today’s competitive digital landscape. It’s an essential part of both web and software development, and no application or website can be built without functional designs at the present time. Together, they refer to the overall digital experience that the user has when interacting with a product (UX) and the visual and interactive components the user sees (UI). Together, UX and UI design and development services create a seamless and intuitive digital experience for users, ensuring they can easily navigate and interact with a product.";

  // UI/UX We Offer

  const uiWhatOfferTitle = "UI/UX Development Services We Offer";
  const uiWhatOfferText = "As a trusted UI/UX design and development company with broad market experience, we offer a range of design services that can help businesses create functional and visually appealing products.";
  const uiWhatOfferCards =
       [
          {
              id:1,
              title: "UI/UX development from scratch",
              text: "Development from scratch involves designing and developing a digital product from the ground up, starting with a blank canvas. The process consists of building UX and UI design, as well as developing the code to bring the design to life.",
              image: "/img/uid/whatDoWeOffer/ui-offer_1.webp"
          },
          {
              id:2,
              title: "Mobile application UI/UX development",
              text: "Mobile UI/UX development services include creating intuitive designs for mobile applications considering the specific characteristics of mobile devices.",
              image: "/img/uid/whatDoWeOffer/ui-offer_2.webp"
          },
          {
              id:3,
              title: "UI/UX design for web development",
              text: "A skilled team of ELITEX developers and designers create robust web application designs regardless of their complexity and size.",
              image: "/img/uid/whatDoWeOffer/ui-offer_3.webp"
          },
          {
              id:4,
              title: "Information architecture",
              text: "Information architecture services facilitate organizing and structuring the product’s content and information. We endeavor to ensure that the product is easy to navigate and the content is easy to comprehend.",
              image: "/img/uid/whatDoWeOffer/ui-offer_4.webp"
          },
          {
              id:5,
              title: "UX and UI modernization",
              text: "Content management systems or CMS services involve designing, building, and managing websites and web applications using a CMS development platform, such as WP, Drupal, or Joomla. ELITEX team can help you create customized websites, plugins, theme customization, content management, website maintenance, and optimizations.",
              image: "/img/uid/whatDoWeOffer/ui-offer_5.webp"
          },
          {
              id:6,
              title: "Cross-platform design solutions",
              text: "It means creating user interfaces that work seamlessly across multiple platforms and devices. It includes designing products compatible with desktops, tablets, and smartphones, as well as different operating systems, such as iOS, Android, and Windows.",
              image: "/img/uid/whatDoWeOffer/ui-offer_6.webp"
          },
      ];

  // UI/UX Dev Process
  const uiDevProcessTitle = "Our UI/UX Design and Development Process";

  const uiDevProcessText = "Although the UI and UX design and development process may vary, the efficient design process typically includes several consistent stages.";

  const uiDevProcessContent = [
      {
          id: 1,
          header:"Research",
          text: "Conduct user research, gather information about the product, and analyze the target audience and specific business requirements, as well as user needs and pain points.",
          arrow: true,
      },
      {
          id: 2,
          header:"Planning",
          text: "Define project requirements, choose design strategies, create user personas, and develop a user flow.",
          arrow: true,
      },
      {
          id: 3,
          header:"Wireframing and prototyping",
          text: "Sketch out a basic design, create a blueprint of the layout, and, later, a working model of the product to perform functionality and usability testing. We create low-fidelity wireframes at the early stage of the product and high-fidelity prototypes at later stages to allow clients to test the product’s functionality.",
          arrow: true,
      },
      {
          id: 4,
          header:"Testing",
          text: "Conduct user testing to evaluate the effectiveness of the design solutions and make necessary adjustments",
          arrow: true,
      },
      {
          id: 5,
          header:"Visual Design",
          text: "Build visual designs such as branding, color schemes, typography, and imagery.",
          arrow: true,
      },
      {
          id: 6,
          header:"Development",
          text: "Transform the user interface and experience design into code and develop the product.",
          arrow: true,
      },
      {
          id: 7,
          header:"Launch, ongoing maintenance, and support",
          text: "Launch the product, gather user feedback to refine the design, and continue to monitor and maintain the product, ensuring it meets user needs as well as your business goals.",
      },
  ];

  // UI/UX Why Choose Elitex

  const uiWhyChooseTitle = "Why ELITEX Is a Good Choice as UI/UX Development Company?";

  const uiWhyChooseSubtitle = "Choosing the right UI/UX development partner can make all the difference in ensuring the creation of goal-driven, usable designs. At ELITEX, we pride ourselves on our expertise in creating exceptional designs that exceed our client’s expectations. Here are just a few reasons why we believe ELITEX is an ideal UI/UX development company for your next project:";

  const uiWhyChooseText = "In case you’re looking for an ideal UI/UX development company, ELITEX has the skills, expertise, and commitment to excellence that you need to create exceptional user experiences and visually appealing design of user interfaces.";

  const uiWhyChooseList = [
      {
          id: 1,
          text: "Our team comprises highly skilled and experienced designers passionate about delivering innovative and user-centered designs. Over 90% of our team are hand-picked Senior and Middle-class designers and engineers."
      },
      {
          id: 2,
          text: "We use advanced technologies and custom solutions to create impressive, aesthetically pleasing, and highly functional designs. "
      },
      {
          id: 3,
          text: "We adhere to the principles of zero bureaucracy and flexibility in order to ensure a smooth communication experience. Honesty, integrity, and transparency are key elements of our approach."
      },
      {
          id: 4,
          text: "We are delivering projects on time and within budget. We understand the importance of meeting deadlines and staying within budget constraints, and we use agile design methodologies to ensure the delivery of high-quality designs efficiently and effectively."
      },
  ]

  // UI/UX FAQ

  const uiFaqTitle = {
      main: "Custom Javascript Development Services",
      strong: "FAQs"
  }
  const uiFaqContent = [
      {
          id: 1,
          name: "What is UI/UX design?",
          text: "UI/UX design refers to the process of creating digital interfaces that are user-friendly and aesthetically pleasing. UX (user experience) focuses on a user's overall experience when interacting with the product. In contrast, UI (user interface) design deals with the visual and interactive elements of the product.",
      },
      {
          id: 2,
          name: "Why is UI/UX design important?",
          text: "In a word, you need UI and UX designs to create attractive digital products providing a positive experience for customers. Design is the first thing that user sees using your product, and that’s why it is so important.",
      },
      {
          id: 3,
          name: "How can UI services improve your project?",
          text: "UI services can help improve your product by enhancing its visual appeal, usability, and accessibility. It increases user engagement, satisfaction, and retention, as well as enhances brand reputation and business success.",
      },
      {
          id: 4,
          name: "How long does a UI/UX app development company take to build a solid design?",
          text: "The time it takes for the company to build a robust design may vary slightly depending on the scope of the project and its complexity. Also, the type of collaboration (for instance, in-house development, outsourcing UI/UX development services, etc.) may impact the project’s time required. It usually takes several weeks to a few months to complete the design process, including all work components.",
      },
  ];

  export {
      uiMainCaptionTitle,
      uiMainCaptionText,
      uiWhatOfferTitle,
      uiWhatOfferCards,
      uiWhatOfferText,
      uiDevProcessTitle,
      uiDevProcessText,
      uiDevProcessContent,
      uiWhyChooseTitle,
      uiWhyChooseSubtitle,
      uiWhyChooseText,
      uiWhyChooseList,
      uiFaqTitle,
      uiFaqContent
  }