import React, {Fragment} from 'react';
import './FeDevProcess.css';
import {
    devProcessContent,
    devProcessTextOne,
    devProcessTextThree,
    devProcessTextTwo
} from "../../mocks";
import ProcessStages from "../ProcessStages/ProcessStages";
import {Spacer} from "../Spacer/Spacer";
import BlueLine from "../BlueLine/BlueLine";

const FeDevProcess = () => {
    return (
            <Fragment >
                <h2 className='h4 align-center'>Our Front-End Development Process: <strong>Step by Step</strong></h2>
                <BlueLine/>
                <p className="fe-development-text fe-process">{devProcessTextOne}</p>
                <p className="fe-development-text fe-process">{devProcessTextTwo}</p>
                <p className="fe-development-text fe-process">{devProcessTextThree}</p>
                <Spacer type="big"/>
                <ProcessStages content={devProcessContent}/>
            </Fragment>
    );
};

export default FeDevProcess;