const  homeClientsSliderHeader = {
    main: "What Our Clients Say About",
    strong: "ELITEX"
};

const uiClientsSliderHeader = {
    main: "What Our",
    strong: "Clients Say"
}

export {
    homeClientsSliderHeader,
    uiClientsSliderHeader
}